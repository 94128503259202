import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import BlitzLogo from "@/inline-assets/blitz.svg";
import { getLocaleString } from "@/util/i18n-helper.mjs";

const Container = styled("div")`
  display: flex;
  align-items: center;
  gap: var(--sp-4);
  padding: var(--sp-1) var(--sp-4) var(--sp-1) var(--sp-2);
  border-radius: var(--br) var(--br) 0 0;
  background: linear-gradient(
    to bottom,
    hsla(var(--shade10-hsl) / 0.5),
    hsla(var(--shade10-hsl) / 0.1)
  );
`;

const Title = styled("div")`
  display: flex;
  align-items: center;
  gap: var(--sp-1);
  margin-bottom: var(--sp-1);
  padding-left: var(--sp-2);

  svg {
    display: inline-block;
    width: var(--sp-4);
    height: var(--sp-4);
  }
`;

const Matches = styled("div")`
  display: flex;
  gap: var(--sp-0_5);
  margin-top: var(--sp-0_5);

  > div {
    flex: 1;
    background: hsla(var(--shade10-hsl) / 0.1);
    border-radius: 0 0 var(--br-sm) var(--br-sm);
    padding: var(--sp-1) 0;
    text-align: center;
    border-top: 3px transparent solid;
  }
`;

const MatchContainer = styled("div")`
  img {
    display: block;
    max-width: var(--sp-8);
    margin: var(--sp-1) auto 0;
    border-radius: var(--br-sm);
  }
`;

const MAX_MATCHES = 7;

export default function Variant2({ attribution, rank, rankScore, matches }) {
  const { t } = useTranslation();
  const damagePerMatch = Math.round(
    matches.reduce((acc, { damage_done: dmg }) => {
      return acc + dmg;
    }, 0) / matches.length || 0,
  );

  return (
    <>
      <Title>
        <BlitzLogo />
        <span className="type-overline">{attribution}</span>
      </Title>
      <Container>
        {rank && <rank.iconLg />}
        <div>
          <div className="type-subtitle1">
            {t("apex:stats.lpWithPoints", "{{lp, number}} LP", {
              lp: rankScore,
            })}
          </div>
          <div className="type-overline shade2">
            {rank &&
              t(rank.tKey, rank.tDefault, {
                tier: rank.rank,
              })}
          </div>
        </div>
        <div>
          <div className="type-subtitle1">
            {getLocaleString(damagePerMatch)}
          </div>
          <div className="type-overline shade2">
            {t("common:stats.damagePerMatch", "DMG/Match")}
          </div>
        </div>
      </Container>
      <Matches>
        {matches
          .slice(0, MAX_MATCHES)
          .map(({ placement, legendImage, legendName }, i) => {
            const label = t(
              "common:stats.placement",
              "{{placement, ordinal}}",
              {
                placement,
              },
            );

            const borderColor = (() => {
              let result = "var(--shade4)";
              if (placement === 1) result = "var(--turq)";
              if (placement === 2) result = "var(--turq-50)";
              if (placement === 3) result = "var(--turq-25)";
              return result;
            })();

            return (
              <MatchContainer
                className={`type-mini ${placement <= 3 ? "shade0" : "shade2"}`}
                key={i}
                style={{
                  borderColor,
                }}
              >
                <p>{label}</p>
                <img src={legendImage} alt={legendName} />
              </MatchContainer>
            );
          })}
      </Matches>
    </>
  );
}
